// // import React from 'react';

// // const ContactPage = () => {
// //     return (
// //         <div className="bg-gray-100 text-gray-800 px-6 md:px-20 py-10 mt-36">
// //             <h1 className="text-3xl font-bold mb-6 text-center">Privacy Policy</h1>
// //             <div className="space-y-6">
// //                 <p>
// //                     At OTR Solutions, we are committed to protecting your privacy and ensuring the confidentiality of your personal information. This privacy policy applies to our website, <a href="https://otrsolutions.site/" className="text-blue-600 underline">https://otrsolutions.site/</a>, and outlines the types of information we collect, how we use it, and the steps we take to protect it.
// //                 </p>

// //                 <h2 className="text-xl font-semibold">Information Collection</h2>
// //                 <p>
// //                     We collect information from you when you visit our website or fill out a form on our site. This information may include your name, email address, phone number, and any other information you provide. We may also collect information about your browsing activity on our website, such as the pages you visit and the links you click on.
// //                 </p>
// //                 <p>
// //                     In addition, we may collect information from cookies and other tracking technologies, such as IP addresses, to personalize your experience on our website and analyze site usage.
// //                 </p>

// //                 <h2 className="text-xl font-semibold">Use of Information</h2>
// //                 <p>
// //                     We use the information we collect from you to improve the services we provide and to better understand our customers’ needs. No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. All other categories exclude text messaging originator opt-in data and consent; this information will not be shared with any third parties. We do not sell or share your personal information with third parties.
// //                 </p>

// //                 <h2 className="text-xl font-semibold">SMS Disclosure</h2>
// //                 <p>
// //                     SMS opt-in or phone numbers for the purpose of SMS are not being shared.
// //                 </p>

// //                 <h2 className="text-xl font-semibold">Your Individual Rights</h2>
// //                 <p>
// //                     You may have certain rights relating to your Personal Data based on applicable local data protection laws. Depending on the applicable laws, these rights may include the right to:
// //                 </p>
// //                 <ul className="list-disc pl-6 space-y-2">
// //                     <li>Request and receive copies of your Personal Data that we hold;</li>
// //                     <li>Request additional information about how we process your Personal Data;</li>
// //                     <li>Correct inaccurate or incomplete Personal Data (taking into account our use of it);</li>
// //                     <li>Request deletion of your Personal Data;</li>
// //                     <li>Restrict or object to our processing of your Personal Data. Where we process Personal Data for direct marketing purposes (either by us or third parties), you may not have to provide a specific reason for such objection;</li>
// //                     <li>Require us (if possible) to transfer your Personal Data to another controller (i.e., data portability);</li>
// //                     <li>Restrict certain disclosures of your Personal Data to third parties;</li>
// //                     <li>
// //                         Not be subject to a decision based solely on automated processing, including profiling, which produces legal effects; and
// //                     </li>
// //                     <li>
// //                         Withdraw your consent to the processing of your Personal Data (to the extent we base processing on consent and not on another lawful basis).
// //                     </li>
// //                 </ul>

// //                 <h2 className="text-xl font-semibold">Security Measures</h2>
// //                 <p>
// //                     We take steps to protect your personal information from unauthorized access, use, or disclosure. This includes using encryption technology when transmitting sensitive information and regularly monitoring our systems for potential security vulnerabilities.
// //                 </p>

// //                 <h2 className="text-xl font-semibold">California Consumer Privacy Act Disclosures</h2>
// //                 <p>
// //                     The categories of personal information we collect, including name, email address, phone number, and browsing activity.
// //                 </p>
// //                 <p>
// //                     The sources from which we collect personal information, such as directly from you or from cookies and tracking technologies.
// //                 </p>
// //                 <p>
// //                     The purposes for which we use personal information, such as to improve our services and understand our customers’ needs. We do not sell or share your personal information with third parties.
// //                 </p>

// //                 <h2 className="text-xl font-semibold">Changes to This Policy</h2>
// //                 <p>
// //                     We reserve the right to update this privacy policy at any time. If we make any changes, we will post the updated policy on our website and notify you by email.
// //                 </p>

// //                 <h2 className="text-xl font-semibold">Contact Us</h2>
// //                 <p>
// //                     If you have any questions or concerns about our privacy policy, please contact us at <a href="mailto:Info@otrsolutions.site" className="text-blue-600 underline">Info@otrsolutions.site</a> or call us at <a href="tel:+13022760440" className="text-blue-600 underline">(302) 276-0440</a>.
// //                 </p>
// //                 <p>
// //                     Address: 1942 Broadway #314C, Boulder CO 80302.
// //                 </p>
// //             </div>
// //         </div>
// //     );
// // };

// // export default ContactPage;




// import React from 'react';

// const ContactPage = () => {
//     return (
//         <div className="bg-gray-100 text-gray-800 px-6 md:px-20 py-10 mt-36">
//             <h1 className="text-3xl font-bold mb-6 text-center">Privacy Policy</h1>
//             <div className="space-y-6">
//                 <p>
//                     At OTR Solutions, we are committed to protecting your privacy and ensuring the confidentiality of your personal information. This privacy policy applies to our website, <a href="https://otrsolutions.site/" className="text-blue-600 underline">https://otrsolutions.site/</a>, and outlines the types of information we collect, how we use it, and the steps we take to protect it.
//                 </p>

//                 <h2 className="text-xl font-semibold">Information Collection</h2>
//                 <p>
//                     We collect information from you when you visit our website, fill out a form on our site, or opt-in for SMS communications. This information may include your name, email address, phone number, and any other details you provide. We may also collect information about your browsing activity, such as the pages you visit and the links you click on.
//                 </p>
//                 <p>
//                     In addition, we may collect information from cookies and other tracking technologies, such as IP addresses, to personalize your experience on our website and analyze site usage.
//                 </p>

//                 <h2 className="text-xl font-semibold">Use of Information</h2>
//                 <p>
//                     We use the information we collect to improve the services we provide and to better understand our customers' needs. No mobile information, including phone numbers for SMS, will be shared with third parties or affiliates for marketing/promotional purposes. We do not sell or share your personal information with third parties.
//                 </p>

//                 <h2 className="text-xl font-semibold">SMS Disclosure</h2>
//                 <p>
//                     SMS opt-in consent and phone numbers for SMS communication are not shared with third parties for marketing purposes. The phone numbers provided through our SMS opt-in process will only be used to send you relevant messages regarding our services. You can opt-out at any time by replying "STOP" to any SMS message you receive from us.
//                 </p>

//                 <h2 className="text-xl font-semibold">Your Individual Rights</h2>
//                 <p>
//                     You may have certain rights relating to your Personal Data based on applicable local data protection laws. Depending on the applicable laws, these rights may include the right to:
//                 </p>
//                 <ul className="list-disc pl-6 space-y-2">
//                     <li>Request and receive copies of your Personal Data that we hold;</li>
//                     <li>Request additional information about how we process your Personal Data;</li>
//                     <li>Correct inaccurate or incomplete Personal Data (taking into account our use of it);</li>
//                     <li>Request deletion of your Personal Data;</li>
//                     <li>Restrict or object to our processing of your Personal Data. Where we process Personal Data for direct marketing purposes (either by us or third parties), you may not have to provide a specific reason for such objection;</li>
//                     <li>Require us (if possible) to transfer your Personal Data to another controller (i.e., data portability);</li>
//                     <li>Restrict certain disclosures of your Personal Data to third parties;</li>
//                     <li>Not be subject to a decision based solely on automated processing, including profiling, which produces legal effects; and</li>
//                     <li>Withdraw your consent to the processing of your Personal Data (to the extent we base processing on consent and not on another lawful basis).</li>
//                 </ul>

//                 <h2 className="text-xl font-semibold">Security Measures</h2>
//                 <p>
//                     We take steps to protect your personal information from unauthorized access, use, or disclosure. This includes using encryption technology when transmitting sensitive information and regularly monitoring our systems for potential security vulnerabilities.
//                 </p>

//                 <h2 className="text-xl font-semibold">California Consumer Privacy Act Disclosures</h2>
//                 <p>
//                     The categories of personal information we collect include name, email address, phone number, and browsing activity.
//                 </p>
//                 <p>
//                     The sources from which we collect personal information include directly from you or from cookies and tracking technologies.
//                 </p>
//                 <p>
//                     The purposes for which we use personal information include improving our services and understanding our customers' needs. We do not sell or share your personal information with third parties.
//                 </p>

//                 <h2 className="text-xl font-semibold">SMS Terms & Conditions</h2>
//                 <p>
//                     By providing your consent to receive SMS communications, you acknowledge and agree to receive text messages from OTR Solutions at the phone number you provide. The frequency of these messages may vary based on your interactions with us. Standard messaging rates may apply. You can opt-out at any time by replying "STOP" to any SMS message you receive.
//                 </p>

//                 <h2 className="text-xl font-semibold">Changes to This Policy</h2>
//                 <p>
//                     We reserve the right to update this privacy policy at any time. If we make any changes, we will post the updated policy on our website and notify you by email.
//                 </p>

//                 <h2 className="text-xl font-semibold">Contact Us</h2>
//                 <p>
//                     If you have any questions or concerns about our privacy policy, please contact us at <a href="mailto:Info@otrsolutions.site" className="text-blue-600 underline">Info@otrsolutions.site</a> or call us at <a href="tel:+13022760440" className="text-blue-600 underline">(302) 276-0440</a>.
//                 </p>
//                 <p>
//                     Address: 1942 Broadway #314C, Boulder CO 80302.
//                 </p>
//             </div>
//         </div>
//     );
// };

// export default ContactPage;



// import React from 'react';

// const PrivacyPolicy = () => {
//     return (
//         <div className="bg-gray-50 text-gray-800 px-6 md:px-20 py-10 mt-36">
//             <h1 className="text-3xl font-bold mb-8 text-center">Privacy Policy</h1>
//             <p className="mb-6 text-lg">
//                 This Privacy Policy outlines the practices and procedures regarding the collection, use, and disclosure of personal data when you engage with our service, including SMS communication.
//                 By using our service, you agree to the collection and use of your information in accordance with this Privacy Policy.
//             </p>

//             {/* Interpretation and Definitions */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">1. Interpretation and Definitions</h2>
//                 <p><strong>Interpretation:</strong> Words with initial capital letters have specific meanings, which apply regardless of singular or plural form.</p>
//                 <p><strong>Definitions:</strong></p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li><strong>Account:</strong> A unique account created to access the Service or parts of the Service.</li>
//                     <li><strong>Affiliate:</strong> An entity that controls, is controlled by, or is under common control with another party.</li>
//                     <li><strong>Company:</strong> OTR Solutions, located at 1942 Broadway #314C, Boulder CO 80302.</li>
//                     <li><strong>Cookies:</strong> Small files placed on your devices containing details of your browsing history.</li>
//                     <li><strong>Country:</strong> United States.</li>
//                     <li><strong>Device:</strong> Any device that can access the Service (e.g., computer, mobile device).</li>
//                     <li><strong>Personal Data:</strong> Any information that can identify or be used to identify you.</li>
//                     <li><strong>Service:</strong> Refers to the Website and other related services.</li>
//                     <li><strong>Service Provider:</strong> A third-party entity that processes data on behalf of the Company.</li>
//                     <li><strong>Usage Data:</strong> Automatically collected data from your use of the Service, including device information and page interactions.</li>
//                     <li><strong>Website:</strong> Refers to OTR Solutions, accessible at <a href="https://otrsolutions.site" className="text-blue-600 underline">https://otrsolutions.site</a>.</li>
//                     <li><strong>You:</strong> The individual accessing or using the Service.</li>
//                 </ul>
//             </section>

//             {/* Collecting and Using Your Personal Data */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">2. Collecting and Using Your Personal Data</h2>
//                 <p><strong>Types of Data Collected:</strong></p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li><strong>Personal Data:</strong> When using our service, we may collect identifiable information, such as email address, name, phone number, and address (state, city, postal code).</li>
//                     <li><strong>Usage Data:</strong> We collect Usage Data automatically, such as IP address, device information, pages visited, and diagnostic data.</li>
//                     <li><strong>Tracking Technologies and Cookies:</strong> We use cookies and similar technologies to track activity and enhance user experience. Cookies are categorized as:
//                         <ul className="list-inside list-disc space-y-2">
//                             <li><strong>Essential Cookies:</strong> Necessary for website functionality.</li>
//                             <li><strong>Functionality Cookies:</strong> Used to remember preferences and improve user experience.</li>
//                         </ul>
//                         You can modify your browser settings to refuse cookies, but this may limit some functionality.
//                     </li>
//                 </ul>
//             </section>

//             {/* SMS Communication */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">3. Terms of Service for SMS Communication</h2>
//                 <p><strong>Consent for SMS Communication:</strong> By consenting to receive SMS communications, you acknowledge that the information collected during the SMS consent process will not be shared with third parties or affiliates.</p>
//                 <p><strong>Opt-In Consent Outside of the Website:</strong> If you provide consent to receive SMS communications outside of our website (e.g., paper form, third-party service, or customer service), the following conditions apply:</p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li>You will be informed of the types of communications you will receive (e.g., job-related updates, placement notifications).</li>
//                     <li>Standard messaging and data rates may apply from your mobile carrier.</li>
//                     <li>By providing your mobile number, you consent to receiving SMS communications as outlined in this Privacy Policy.</li>
//                     <li>You can opt-out by texting "STOP" to any message.</li>
//                 </ul>
//                 <p><strong>Types of SMS Communications:</strong></p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li><strong>For Staff:</strong> Updates on job-related matters, shift schedules, and engagement.</li>
//                     <li><strong>For Clients:</strong> Notifications about placement, availability, and emergencies.</li>
//                     <li><strong>For Job Applicants:</strong> Application status, onboarding materials, and employment-related information.</li>
//                 </ul>
//                 <p><strong>Standard Messaging Disclosures:</strong></p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li>Message and data rates may apply based on your mobile carrier.</li>
//                     <li>HELP: For assistance with SMS communications, reply "HELP" to any message.</li>
//                     <li>Opt-Out: To stop receiving SMS communications, reply "STOP" to any message.</li>
//                     <li>Message Frequency: The frequency of messages will vary based on your relationship with us.</li>
//                     <li>Associated Fees: Standard messaging rates may apply, depending on your mobile carrier.</li>
//                     <li>Your phone number and consent for SMS will not be shared with third parties.</li>
//                 </ul>
//             </section>

//             {/* Use of Personal Data */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">4. Use of Your Personal Data</h2>
//                 <p>We may use your personal data for the following purposes:</p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li>To provide and maintain our service, including monitoring usage.</li>
//                     <li>To manage your account and registration.</li>
//                     <li>To contact you with updates, news, and special offers.</li>
//                     <li>To process requests and improve our service.</li>
//                 </ul>
//             </section>

//             {/* Retention of Personal Data */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">5. Retention of Your Personal Data</h2>
//                 <p>We retain your personal data only as long as necessary to fulfill the purposes outlined in this policy, comply with legal obligations, and resolve disputes. You may request deletion of your personal data by contacting us.</p>
//             </section>

//             {/* Disclosure of Personal Data */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">6. Disclosure of Your Personal Data</h2>
//                 <p>We may disclose your personal data in certain circumstances:</p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li><strong>Legal Requirements:</strong> To comply with legal obligations.</li>
//                     <li><strong>Business Transactions:</strong> In case of mergers, acquisitions, or business sales.</li>
//                     <li><strong>Public Authority Requests:</strong> As required by law.</li>
//                 </ul>
//             </section>

//             {/* Security of Your Personal Data */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">7. Security of Your Personal Data</h2>
//                 <p>While we take steps to protect your personal data, no method of transmission over the internet is 100% secure. We cannot guarantee absolute security but work to protect your data.</p>
//             </section>

//             {/* Unsubscribing and Removing Personal Data */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">8. Unsubscribing from SMS Communications and Removing Personal Data</h2>
//                 <p>To stop receiving SMS communications, reply "STOP" to any message from us. To request the removal of your personal data, contact us at:</p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li>Email: <a href="mailto:Info@otrsolutions.site" className="text-blue-600 underline">Info@otrsolutions.site</a></li>
//                     <li>Phone: <a href="tel:+13022760440" className="text-blue-600 underline">(302) 276-0440</a></li>
//                 </ul>
//             </section>

//             {/* Changes to Privacy Policy */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">9. Changes to this Privacy Policy</h2>
//                 <p>We may update this Privacy Policy and will notify you of any changes by posting the updated policy on this page.</p>
//             </section>

//             {/* Contact Us */}
//             <section className="space-y-6">
//                 <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
//                 <p>If you have any questions or concerns, please contact us:</p>
//                 <ul className="list-disc pl-6 space-y-3">
//                     <li>Email: <a href="mailto:Info@otrsolutions.site" className="text-blue-600 underline">Info@otrsolutions.site</a></li>
//                     <li>Phone: <a href="tel:+13022760440" className="text-blue-600 underline">(302) 276-0440</a></li>
//                 </ul>
//             </section>
//         </div>
//     );
// };

// export default PrivacyPolicy;

import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="bg-gray-50 text-gray-800 px-6 md:px-20 py-10 mt-36 text-lg">
            <h1 className="text-4xl font-bold mb-8 text-center">Privacy Policy</h1>
            <p className="mb-6">
                This Privacy Policy outlines the practices and procedures regarding the collection, use, and disclosure of personal data when you engage with our service, including SMS communication. By using our service, you consent to the collection and use of your information in accordance with this Privacy Policy.
            </p>

            {/* Interpretation and Definitions */}
            <section className="space-y-6">
                <h2 className="text-3xl font-semibold mb-4">1. Interpretation and Definitions</h2>
                <p><strong>Interpretation:</strong> Words with initial capital letters hold specific meanings, regardless of singular or plural forms.</p>
                <p><strong>Definitions:</strong></p>
                <ul className="list-disc pl-6 space-y-3">
                    <li><strong>Account:</strong> A personal account created to access the Service.</li>
                    <li><strong>Cookies:</strong> Small files that store browsing information.</li>
                    <li><strong>Personal Data:</strong> Any information capable of identifying you.</li>
                    <li><strong>Service:</strong> Refers to the Website and associated services.</li>
                    <li><strong>Usage Data:</strong> Automatically collected data from interactions with the Service.</li>
                    <li><strong>You:</strong> The person accessing or using the Service.</li>
                </ul>
            </section>

            {/* SMS Communication Consent */}
            <section className="space-y-6">
                <h2 className="text-3xl font-semibold mb-4">2. SMS Communication Consent</h2>
                <p><strong>Your Consent:</strong> By consenting to receive SMS communications, you acknowledge that the phone numbers collected for this purpose will not be shared with third parties for marketing purposes. These numbers are used solely for sending you relevant notifications.</p>

                <p><strong>Types of Messages:</strong> Upon agreeing to receive SMS communications, you may get messages regarding:</p>
                <ul className="list-disc pl-6 space-y-3">
                    <li>Appointment reminders</li>
                    <li>Follow-up messages</li>
                    <li>Billing inquiries</li>
                    <li>Exclusive promotions (where applicable)</li>
                </ul>
                <p><strong>Example Message:</strong> "Reminder: Your appointment with Dr. John Doe at ABC Clinic is on January 25, 2025, at 10:00 AM. Reply STOP to opt-out."</p>

                <p><strong>Message Frequency:</strong> The frequency of messages varies based on the type of communication. Example: "You may receive up to 2 SMS messages per week about your appointments or account status."</p>

                <p><strong>Fees for SMS Messages:</strong> Standard message and data rates may apply according to your mobile carrier's pricing plan. These fees could differ for domestic and international messages.</p>

                <p><strong>How to Opt-In:</strong> You can opt-in to receive messages from us via:</p>
                <ul className="list-disc pl-6 space-y-3">
                    <li>Verbal consent during a conversation</li>
                    <li>Submission of an online form</li>
                    <li>Filling out a paper consent form</li>
                </ul>

                <p><strong>How to Opt-Out:</strong> You can stop receiving SMS messages at any time. Simply reply with "STOP" to any message you receive. You can also contact us directly to remove your number from our list.</p>

                <p><strong>Need Help?</strong> If you need assistance with SMS communication, reply with "HELP" or contact us directly at <a href="mailto:Info@otrsolutions.site" className="text-blue-600 underline">Info@otrsolutions.site</a> or call <a href="tel:+13022760440" className="text-blue-600 underline">(302) 276-0440</a>.</p>

                <p><strong>Additional Options:</strong> You may also choose not to check the SMS consent box on any forms if you do not wish to receive messages.</p>

                <p><strong>Standard Messaging Disclosures:</strong></p>
                <ul className="list-disc pl-6 space-y-3">
                    <li>Message and data rates may apply depending on your carrier.</li>
                    <li>You can opt-out at any time by replying "STOP".</li>
                    <li>For assistance, text "HELP" or visit our <a href="/privacy" className="text-blue-600 underline">Privacy Policy</a> and <a href="/tc" className="text-blue-600 underline">Terms and Conditions</a>.</li>
                    <li>Message frequency may vary based on the nature of the communication.</li>
                </ul>
            </section>

            {/* Use of Personal Data */}
            <section className="space-y-6">
                <h2 className="text-3xl font-semibold mb-4">3. Use of Personal Data</h2>
                <p>We use the data collected through SMS and other means to:</p>
                <ul className="list-disc pl-6 space-y-3">
                    <li>Provide our service and ensure smooth operation.</li>
                    <li>Respond to your inquiries and requests.</li>
                    <li>Offer updates, promotions, or offers where applicable.</li>
                </ul>
            </section>

            {/* Retention and Deletion of Personal Data */}
            <section className="space-y-6">
                <h2 className="text-3xl font-semibold mb-4">4. Retention and Deletion of Your Data</h2>
                <p>Your personal data is retained as necessary for the purposes outlined in this policy. You may request the removal of your data by contacting us at any time.</p>
            </section>

            {/* Contact Us */}
            <section className="space-y-6">
                <h2 className="text-3xl font-semibold mb-4">5. Contact Us</h2>
                <p>If you have any questions or need further assistance, feel free to contact us:</p>
                <ul className="list-disc pl-6 space-y-3">
                    <li>Email: <a href="mailto:Info@otrsolutions.site" className="text-blue-600 underline">Info@otrsolutions.site</a></li>
                    <li>Phone: <a href="tel:+13022760440" className="text-blue-600 underline">(302) 276-0440</a></li>
                </ul>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
