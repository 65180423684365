import React from 'react';
import forwordYelloArrow from "../assets/Images/forwordYelloArrow.svg";
import YellowYoutube from "../assets/Images/Yellow-youtube.svg";
import YellowLinkIn from "../assets/Images/Yellow-Linkin.svg";
import YellowFacebook from "../assets/Images/Yellow-facebook.svg";
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="bg-[#11265D] text-white md:px-[20%] py-16">
            {/* Main Footer Section */}
            <div className="px-5 md:flex md:justify-between md:gap-16">
                {/* Links Section */}
                <div className="pb-10 md:pb-0">
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <img className="w-3" src={forwordYelloArrow} alt="Arrow" />
                            <Link to={"/"} className="font-bold text-xl hover:text-[#FFEC55]">
                                Home
                            </Link>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <img className="w-3" src={forwordYelloArrow} alt="Arrow" />
                            <Link to={"/pricing"} className="font-bold text-xl hover:text-[#FFEC55]">
                                Prices
                            </Link>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <img className="w-3" src={forwordYelloArrow} alt="Arrow" />
                            <Link to={"/about"} className="font-bold text-xl hover:text-[#FFEC55]">
                                About
                            </Link>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <img className="w-3" src={forwordYelloArrow} alt="Arrow" />
                            <Link to={"/contact-us"} className="font-bold text-xl hover:text-[#FFEC55]">
                                Contact
                            </Link>
                        </div>
                    </div>
                    <div className="mb-4">
                        <div className="flex items-center gap-2">
                            <img className="w-3" src={forwordYelloArrow} alt="Arrow" />
                            <Link to={"/privacy"} className="font-bold text-xl hover:text-[#FFEC55]">
                            Privacy Policy
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Contact Information Section */}
                <div className="text-center md:text-left">
                    <p className="text-[#FFDD00] font-bold text-xl mb-4">1942 Broadway #314C Boulder CO 80302</p>
                    <p className="text-[#FFDD00] font-bold text-xl mb-4">(302) 276-0440</p>
                    <p className="text-[#FFDD00] font-bold text-xl mb-4">Info@otrsolutions.site</p>

                    {/* Social Media Icons */}
                    <div className="flex justify-center md:justify-start gap-6 mt-6">
                        <img src={YellowYoutube} alt="YouTube" className="w-6 h-6 cursor-pointer hover:opacity-80" />
                        <img src={YellowLinkIn} alt="LinkedIn" className="w-6 h-6 cursor-pointer hover:opacity-80" />
                        <img src={YellowFacebook} alt="Facebook" className="w-6 h-6 cursor-pointer hover:opacity-80" />
                    </div>
                </div>
            </div>

            {/* Footer Bottom Section */}
            <div className="mt-10 text-center border-t border-[#FFDD00] pt-5 text-[#FFDD00]">
                <p className="text-lg font-bold">The #1 Dispatch Services</p>
                <p className="text-sm mt-1">Copyright 2018 - 2024 OTR Solution Agency Inc. All Rights Reserved.</p>
                <p className="text-sm mt-1">Designed by QAZI</p>
            </div>
        </footer>
    );
};

export default Footer;
