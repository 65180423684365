// import React from 'react';

// const SmsTerms = () => {
//     return (
//         <div className="max-w-4xl mx-auto p-8">
//             <h1 className="text-3xl font-bold text-gray-800 mb-4">SMS Terms & Conditions</h1>
//             <p className="text-lg text-gray-700 mb-4">
//                 By providing your consent to receive SMS communications, you acknowledge and agree to receive text messages from OTR Solution LLC at the phone number you provide.
//             </p>
//             <p className="text-lg text-gray-700 mb-4">
//                 Message Frequency: The frequency of messages may vary depending on your interactions with us.
//             </p>
//             <p className="text-lg text-gray-700 mb-4">
//                 Standard messaging rates will apply. You can opt-out at any time by texting "STOP" to the number from which you received the message.
//             </p>
//             <p className="text-lg text-gray-700 mb-4">
//                 For assistance, text "HELP" to any text message you receive, or contact us directly at (Phone Number). You can also reach us via email at (Email Address).
//             </p>
//             <p className="text-lg text-gray-700 mb-4">
//                 Information obtained as part of the SMS consent process will not be shared with third parties for marketing purposes.
//             </p>
//         </div>
//     );
// };

// export default SmsTerms;



import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      {/* <ContactPage /> */}
      <div>
      <div className="max-w-4xl mx-auto p-8 mt-48">
            <h1 className="text-3xl font-bold text-gray-800 mb-4">SMS Terms & Conditions</h1>
            <p className="text-lg text-gray-700 mb-4 font-bold ">
              Your SMS opt-in consent or phone numbers for SMS purposes will NOT be shared with third parties and affiliates for marketing purposes.</p>
            <p className="text-lg text-gray-700 mb-4">
                By providing your consent to receive SMS communications, you acknowledge and agree to receive text messages from OTR Solution LLC at the phone number you provide.
            </p>
            <p className="text-lg text-gray-700 mb-4">
                Message Frequency: The frequency of messages may vary depending on your interactions with us.
            </p>
            <p className="text-lg text-gray-700 mb-4">
                Standard messaging rates will apply. You can opt-out at any time by texting "STOP" to the number from which you received the message.
            </p>
            <p className="text-lg text-gray-700 mb-4">
                For assistance, text "HELP" to any text message you receive, or contact us directly at <a href="tel:+13022760440" className="text-blue-600 underline">(302) 276-0440</a>. You can also reach us via email at (<a href="mailto:Info@otrsolutions.site" className="text-blue-600 underline">Info@otrsolutions.site</a>).
            </p>
            <p className="text-lg text-gray-700 mb-4">
                Information obtained as part of the SMS consent process will not be shared with third parties for marketing purposes.
            </p>
        </div>
        </div>
      <Footer />
    </div>
  )
}

export default PrivacyPolicy
