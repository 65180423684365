import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
// import Data from '../CompanyInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
// import logo from "../assets/Images/Logo-main.svg";
import logo from "../assets/Images/LogoMain.png";
// import forwordYelloArrow from "../assets/Images/forwordYelloArrow.svg";

import { useCallback } from 'react';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const location = useLocation();

    const isActive = (path) => location.pathname === path;



    const controlNavbar = useCallback(() => {
        if (window.scrollY > lastScrollY) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
        setLastScrollY(window.scrollY);
    }, [lastScrollY]);

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);

        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [controlNavbar]);

    // Rest of your component...


    return (
        <div>
            {/* Apply a class to hide/show the navbar based on scroll */}
            <div className={`transition-all duration-300 overflow-hidden ${isVisible ? 'translate-y-0' : '-translate-y-full'} fixed top-0 left-0 w-full z-50`}>
                <div className='bg-[#45505c] text-white font-bold text-wh text-center py-3 border-t-2  border-[#ff9d49]'>
                    Phone # (302) 276-0440
                </div>

                <div className={`bg-[#11265D] text-white md:px-48 flex justify-between text-center items-center px-5 py-2`}>
                    <div className="name pr-10 ">
                        <img className='md:h-[12vh] h-[9.5vh]' src={logo} alt="Logo" />
                    </div>
                    <div className='hidden lg:block'>
                        <ul className='flex gap-6 text-xl font-bold'>
                            <Link to={"/"} className={`pb-3 ${isActive('/') ? `border-b-2 border-[#f5821f]` : `hover:border-b-2  border-[#f5821f]`} hover:text-[#f5821f]`}>
                                Home
                            </Link>

                            <Link to={"/Pricing"} className={`pb-3 ${isActive('/prices') ? `border-b-2 border-[#f5821f]` : `hover:border-b-2 border-[#f5821f]`} hover:text-[#f5821f]`}>
                                Prices
                            </Link>

                            <Link to={"/about"} className={`pb-3 ${isActive('/about') ? `border-b-2 border-[#f5821f]` : `hover:border-b-2 border-[#f5821f]`} hover:text-[#f5821f]`}>
                                About
                            </Link>

                            <Link to={"/contact-us"} className={`pb-3 ${isActive('/Contact') ? `border-b-2 border-[#f5821f]` : `hover:border-b-2 border-[#f5821f]`} hover:text-[#f5821f]`}>
                                Contact
                            </Link>

                            
                            <Link to={"/privacy"} className={`pb-3 ${isActive('/Contact') ? `border-b-2 border-[#f5821f]` : `hover:border-b-2 border-[#f5821f]`} hover:text-[#f5821f]`}>
                            PrivacyPolicy
                            </Link>
                        </ul>
                    </div>

                    {/* Mobile menu toggle */}
                    <div className="toggle lg:hidden flex gap-2" onClick={() => setIsOpen(!isOpen)}>
                        <div>
                            <h1 className='text-xl font-bold'>MENU</h1>
                        </div>
                        <div className='bg-black px-2 py-1 text-white'>
                            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            {isOpen && (
                <div className='relative lg:hidden'>
                    <ul className='absolute bg-white opacity-95 shadow-2xl top-0 right-0 w-1/2 ml-auto gap-6 text-xl font-bold px-5 py-2 z-20'>
                        <Link to={"/"} className={`block pb-3 ${isActive('/') ? `border-b-2 border-[#f5821f]` : `hover:border-b-2 border-[#f5821f]`} hover:text-[#f5821f]`}>
                            Home
                        </Link>

                        <Link to={"/Pricing"} className={`block pb-3 ${isActive('/prices') ? `border-b-2 border-[#f5821f]` : `hover:border-b-2 border-[#f5821f]`} hover:text-[#f5821f]`}>
                            Prices
                        </Link>

                        <Link to={"/about"} className={`block pb-3 ${isActive('/about') ? 'border-b-2 border-[#f5821f]' : 'hover:border-b-2 border-[#f5821f]'} hover:text-gray-700`}>
                            About
                        </Link>

                        <Link to={"/contact-us"} className={`block pb-3 ${isActive('/Contact') ? 'border-b-2 border-[#f5821f]' : 'hover:border-b-2 border-[#f5821f]'} hover:text-gray-700`}>
                            Contact
                        </Link>

                    </ul>
                </div>
            )}
        </div>
    );
};

export default Navbar;