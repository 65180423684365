import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
// import MaintenancePage from './pages/MaintenancePage';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import SmsTerms from './pages/SmsTerms';

// import Test from './Pages/Test';


function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/maintenace" element={<MaintenancePage />} /> */}
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/tc" element={<SmsTerms />} />
        
      </Routes>
    </Router>
  );
}

export default App;